import React, { useContext } from "react";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { AuthContext } from "../../lib/AuthContext";
import XanoAPI from "../../lib/XanoAPI";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { Input } from "../../components/ui/input";
import { Label } from "../../components/ui/label";
import { getStates } from "../../lib/utils";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "../../components/ui/form";

// Singleton API instance
const api = XanoAPI.getInstance();

// Define the form validation schema
const formSchema = z.object({
  user_id: z.number().optional(),
  firstName: z
    .string()
    .min(2, "First Name must be at least 2 characters.")
    .nonempty("First Name is required"),
  lastName: z
    .string()
    .min(2, "Last Name must be at least 2 characters.")
    .nonempty("Last Name is required"),
  phoneNumber: z
    .string()
    .min(10, "Phone number must be at least 10 digits long.")
    .regex(/^\d+$/, "Phone number should only contain digits")
    .nonempty("Phone number is required"),
  stateProvince: z
    .string()
    .min(2, "Please provide a valid state or province")
    .nonempty("State/Province is required"),
});

// Infer the type from the schema
type FormData = z.infer<typeof formSchema>;

export function AddClientModal() {
  const auth = useContext(AuthContext);
  const methods = useForm<FormData>({
    resolver: zodResolver(formSchema),
    mode: "all",
    defaultValues: {
      user_id: 0,
      firstName: "",
      lastName: "",
      phoneNumber: "",
      stateProvince: "",
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = methods;

  const onSubmit = async (data: FormData) => {
    const userId = auth.currentUser?.id || api.getUserId();

    if (userId === 0) {
      console.error("Invalid user ID. Could Not Submit the form");
      return;
    }

    const clientData: ClientType = {
      ...data,
      user_id: userId,
    };

    try {
      const newClient = await api.addClient(clientData);
      if (newClient.id) window.location.replace(`/edit-client/${newClient.id}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="bg-accent">Add Client</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[525px]">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogHeader>
              <DialogTitle>Personal Information</DialogTitle>
              <DialogDescription>
                Add the basic client information here before you can continue.
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-1 gap-4">
                <Label htmlFor="firstName" className="text-left">
                  First Name
                </Label>
                <FormControl>
                  <Controller
                    name="firstName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Input
                          {...field}
                          id="firstName"
                          placeholder="Client's first name ..."
                          className="w-full"
                        />
                        {error && (
                          <FormMessage className="text-red-500 font-normal text-xs">
                            {error.message}
                          </FormMessage>
                        )}
                      </>
                    )}
                  />
                </FormControl>
              </div>

              <div className="grid grid-cols-1 gap-4">
                <Label htmlFor="lastName" className="text-left">
                  Last Name
                </Label>
                <FormControl>
                  <Controller
                    name="lastName"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Input
                          {...field}
                          id="lastName"
                          placeholder="Client's last name ..."
                          className="w-full"
                        />
                        {error && (
                          <FormMessage className="text-red-500 font-normal text-xs">
                            {error.message}
                          </FormMessage>
                        )}
                      </>
                    )}
                  />
                </FormControl>
              </div>

              <div className="grid grid-cols-1 gap-4">
                <Label htmlFor="phoneNumber" className="text-left">
                  Phone Number
                </Label>
                <FormControl>
                  <Controller
                    name="phoneNumber"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <>
                        <Input
                          {...field}
                          id="phoneNumber"
                          placeholder="Client's phone number ..."
                          className="w-full"
                        />
                        {error && (
                          <FormMessage className="text-red-500 font-normal text-xs">
                            {error.message}
                          </FormMessage>
                        )}
                      </>
                    )}
                  />
                </FormControl>
              </div>

              <div className="grid grid-cols-1 gap-4">
                <Label htmlFor="stateProvince" className="text-left">
                  State / Province
                </Label>
                <FormControl>
                  <Controller
                    name="stateProvince"
                    control={control}
                    defaultValue=""
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <>
                        <Select
                          onValueChange={(val) => onChange(val)}
                          value={value}
                        >
                          <SelectTrigger className="w-full">
                            <SelectValue placeholder={`Select State`} />
                          </SelectTrigger>
                          <SelectContent>
                            <SelectGroup>
                              {getStates().map((option) => (
                                <SelectItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </SelectItem>
                              ))}
                            </SelectGroup>
                          </SelectContent>
                        </Select>
                        {error && (
                          <FormMessage className="text-red-500 font-normal text-xs">
                            {error.message}
                          </FormMessage>
                        )}
                      </>
                    )}
                  />
                </FormControl>
              </div>
            </div>
            <DialogFooter>
              <Button variant="outline" type="submit" disabled={!isValid}>
                Save changes
              </Button>
            </DialogFooter>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
