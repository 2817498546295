import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm, FormProvider, SubmitHandler } from "react-hook-form";
import { Icons } from "../../../utils/icons";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardFooter,
} from "../../ui/card";
import { Button } from "../../ui/button";
import InviteModal from "../../modals/agent-invite-modal";
import { UserStatusModal } from "../../modals/user-status-modal";
import { toast } from "../../ui/use-toast";
import TextController from "../../form-fields/TextController";
import Loader from "../../loader/loader";
import XanoAPI from "../../../lib/XanoAPI";
import TeamMemberCard from "./team/team-member-card";
import TeamMembersList from "./team/team-member-list";
import RoleManagement from "./team/role-management";

interface FormValues {
  agencyName: string;
}

const defaultValues: FormValues = {
  agencyName: "",
};

const MyTeam: React.FC = () => {
  const [agencyId, setAgencyId] = useState<number | null>(null);
  const [agencyName, setAgencyName] = useState("");
  const [agencyCreator, setAgencyCreator] = useState<number | null>(null);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const methods = useForm<FormValues>({
    defaultValues,
  });

  const fetchAgencyNameAndMembers = async () => {
    try {
      setLoading(true);
      const api = XanoAPI.getInstance();
      const currentUser = await api.getCurrentUser(); // Fetch current user

      if (currentUser) {
        const { agency_id, name, email, id, user_agency, user_permissions } =
          currentUser;
        const agency = user_agency[0];

        setAgencyId(agency.id);
        setAgencyCreator(agency.agency_creator);
        setAgencyName(agency.agency_name);
        methods.reset({ agencyName: agency.agency_name });

        const teamMembers = agency.members.map((user: any) => ({
          id: parseInt(user.id),
          name: user.name,
          email: user.email,
          role: agency.agency_creator === parseInt(user.id) ? "admin" : "agent",
          status: user.status as "active" | "deactivated" | "invited",
          permissions: user.user_permissions,
          permissions_id: user.permissions_id,
        }));

        setTeamMembers(teamMembers);
      }
    } catch (error) {
      console.error("Error fetching agency name and members:", error);
      toast({
        title: "Error",
        description: "Failed to fetch agency name and members.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAgencyNameAndMembers();
  }, [methods]);

  const handleInvite: SubmitHandler<any> = async (data) => {
    try {
      const api = XanoAPI.getInstance();
      await api.sendInviteEmail(data.email, data.name);

      const newMember: TeamMember = {
        id: Date.now(),
        name: data.name,
        email: data.email,
        agency_id: data.agency_id,
        role: "agent",
        status: "invited",
        permissions: [],
        permissions_id: data.permissions_id,
      };
      setTeamMembers([...teamMembers, newMember]);
      toast({
        title: "Invite Sent",
        description: "An invitation has been sent to the team member.",
      });
      setInviteModalOpen(false);
    } catch (error) {
      console.error("Error sending invite:", error);
      toast({
        title: "Invite Failed",
        description: "There was an error sending the invitation.",
      });
    }
  };

  const handleAgencyNameSave: SubmitHandler<FormValues> = async (data) => {
    try {
      const api = XanoAPI.getInstance();
      if (agencyId) {
        await api.updateAgencyName(agencyId, data.agencyName);
        setAgencyName(data.agencyName);
        toast({
          title: "Agency Name Updated",
          description: "The agency name has been successfully updated.",
        });
      }
    } catch (error) {
      console.error("Error updating agency name:", error);
      toast({
        title: "Update Failed",
        description: "There was an error updating the agency name.",
      });
    }
  };

  return (
    <FormProvider {...methods}>
      <div>
        <h2 className="text-xl font-semibold text-accent mb-4">My Team</h2>
        <form
          onSubmit={methods.handleSubmit(handleAgencyNameSave)}
          className="mb-2 grid grid-cols-[1fr_auto] gap-2  items-center"
        >
          <TextController
            name="agencyName"
            label="Agency Name"
            placeholder="Enter agency name"
            defaultValue={agencyName}
          />

          <Button type="submit" className="bg-accent mt-2">
            <Icons.save className="mr-2" />
            Save
          </Button>
        </form>

        <h3 className="text-xl font-semibold text-accent mb-4 mt-8">
          Team Members
        </h3>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {loading ? (
            <Loader />
          ) : (
            <TeamMembersList
              teamMembers={teamMembers}
              agencyCreator={agencyCreator}
              fetchAgencyNameAndMembers={fetchAgencyNameAndMembers}
            />
          )}
        </div>
        <Button
          onClick={() => setInviteModalOpen(true)}
          className="mb-4 bg-accent"
        >
          <Icons.addUser className="mr-2" />
          Invite Team Member
        </Button>
        <InviteModal
          isOpen={isInviteModalOpen}
          onClose={() => setInviteModalOpen(false)}
          onInvite={handleInvite}
        />
        {agencyId && <RoleManagement agencyId={agencyId} />}
      </div>
    </FormProvider>
  );
};

export default MyTeam;
