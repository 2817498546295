import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider } from "react-hook-form";
import * as z from "zod";
import React, { useState, useEffect } from "react";
import { Button } from "../../ui/button";
import { toast } from "../../../components/ui/use-toast";
import PercentageController from "../../form-fields/PercentageController";
import FloatController from "../../form-fields/FloatController";
// api and auth
import { useAuth } from "../../../lib/AuthContext";
import XanoAPI from "../../../lib/XanoAPI";

const userPreferencesSchema = z.object({
  user_id: z.number(),
  inflation: z.number().optional(),
  annual_raise: z.number().optional(),
  before_retirement: z.number().optional(),
  during_retirement: z.number().optional(),
  payout_percent: z.number().optional(),
  federal_tsp_g: z.number().optional(),
  federal_tsp_f: z.number().optional(),
  federal_tsp_c: z.number().optional(),
  federal_tsp_s: z.number().optional(),
  federal_tsp_i: z.number().optional(),
});

type UserPreferencesValues = z.infer<typeof userPreferencesSchema>;

export function UserPreferencesForm() {
  const auth = useAuth();
  const api = XanoAPI.getInstance();
  const [preferences, setPreferences] = useState<UserPreferencesValues | null>(
    null
  );

  const form = useForm<UserPreferencesValues>({
    resolver: zodResolver(userPreferencesSchema),
    defaultValues: {
      user_id: api.getUserId(),
      inflation: 0,
      annual_raise: 0,
      before_retirement: 0,
      during_retirement: 0,
      payout_percent: 0,
      federal_tsp_g: 0,
      federal_tsp_f: 0,
      federal_tsp_c: 0,
      federal_tsp_s: 0,
      federal_tsp_i: 0,
    },
    mode: "onChange",
  });

  useEffect(() => {
    const fetchPreferences = async () => {
      const userPreferences = await api.getUserPreferences();
      if (userPreferences) {
        setPreferences(userPreferences as UserPreferencesValues);
        form.reset(userPreferences as UserPreferencesValues);
      } else {
        form.reset({
          user_id: api.getUserId(),
          inflation: 0,
          annual_raise: 0,
          before_retirement: 0,
          during_retirement: 0,
          payout_percent: 0,
          federal_tsp_g: 0,
          federal_tsp_f: 0,
          federal_tsp_c: 0,
          federal_tsp_s: 0,
          federal_tsp_i: 0,
        });
      }
    };

    fetchPreferences();
  }, [auth.currentUser, form, api]);

  async function onSubmit(data: UserPreferencesValues) {
    if (!auth.currentUser || !auth.currentUser.id) {
      toast({
        title: "Error",
        description: "User ID is not available. Cannot update preferences.",
      });
      return;
    }

    try {
      const updatedPreferences = await api.updatePreferencesByUserId(
        auth.currentUser.id.toString(),
        data
      );

      toast({
        title: "User Preferences Updated",
        description: "Your preferences have been successfully updated.",
      });

      if (updatedPreferences) {
        setPreferences(updatedPreferences as UserPreferencesValues);
      }
    } catch (error) {
      console.error("Error updating preferences:", error);
      toast({
        title: "Update Failed",
        description:
          "There was an error updating your preferences. Please try again.",
      });
    }
  }

  return (
    <div>
      <h2 className="text-xl font-semibold text-accent mb-4">
        User Preferences
      </h2>

      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit, (errors) => {
            console.log("Submit was attempted", errors);
          })}
          className="space-y-6"
        >
          <PercentageController
            name="inflation"
            label="Inflation Rate"
            placeholder="3"
            defaultValue={preferences?.inflation || 0}
            fixed="4"
            infoMessage="Specify the inflation rate"
          />

          <PercentageController
            name="annual_raise"
            label="Annual Raise"
            placeholder="1.5"
            defaultValue={preferences?.annual_raise || 0}
            fixed="4"
            infoMessage="Specify the default annual raise."
          />

          <h3 className="text-l font-semibold text-accent mb-4">
            Rates of Return
          </h3>

          <PercentageController
            name="before_retirement"
            label="Before Retirement"
            placeholder="6"
            defaultValue={preferences?.before_retirement || 0}
            fixed="4"
            infoMessage="Specify the default percentage of income needed before retirement."
          />

          <PercentageController
            name="during_retirement"
            label="During Retirement"
            placeholder="4"
            defaultValue={preferences?.during_retirement || 0}
            fixed="4"
            infoMessage="Specify the default percentage of income needed during retirement."
          />

          <PercentageController
            name="payout_percent"
            label="Payout Percent"
            placeholder="4"
            defaultValue={preferences?.payout_percent || 0}
            fixed="4"
            infoMessage="Specify the default payout percentage."
          />

          <h3 className="text-l font-semibold text-accent mb-4">
            Federal TSP Rates of Return
          </h3>

          <PercentageController
            name="federal_tsp_g"
            label="G Fund"
            placeholder="0"
            defaultValue={preferences?.federal_tsp_g || 0}
            fixed="4"
            infoMessage="Specify the G Fund rate of return."
          />

          <PercentageController
            name="federal_tsp_f"
            label="F Fund"
            placeholder="0"
            defaultValue={preferences?.federal_tsp_f || 0}
            fixed="4"
            infoMessage="Specify the F Fund rate of return."
          />

          <PercentageController
            name="federal_tsp_c"
            label="C Fund"
            placeholder="0"
            defaultValue={preferences?.federal_tsp_c || 0}
            fixed="4"
            infoMessage="Specify the C Fund rate of return."
          />

          <PercentageController
            name="federal_tsp_s"
            label="S Fund"
            placeholder="0"
            defaultValue={preferences?.federal_tsp_s || 0}
            fixed="4"
            infoMessage="Specify the S Fund rate of return."
          />

          <PercentageController
            name="federal_tsp_i"
            label="I Fund"
            placeholder="0"
            defaultValue={preferences?.federal_tsp_i || 0}
            fixed="4"
            infoMessage="Specify the I Fund rate of return."
          />

          <Button type="submit" className="bg-accent">
            Update Preferences
          </Button>
        </form>
      </FormProvider>
    </div>
  );
}
