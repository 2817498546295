"use client";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../lib/AuthContext";
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
} from "../../components/ui/card";

const Statistics: React.FC = () => {
  const auth = useAuth();
  const [clientsNo, setClientsNo] = useState(auth?.clientCount);

  const stats = [
    {
      title: "Clients",
      value: clientsNo,
      subtitle: "+20.1% from last month",
    },
    {
      title: "Subscriptions",
      value: "+2350",
      subtitle: "+180.1% from last month",
    },
    {
      title: "Sales",
      value: "+345",
      subtitle: "+10% since last month",
    },
  ];

  return (
    <div
      className={`grid gap-4 md:grid-cols-2 lg:grid-cols-${stats.length} mb-8 hidden`}
    >
      {stats.map((stat, index) => (
        <Card key={index}>
          <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
            <CardTitle className="text-sm font-medium">{stat.title}</CardTitle>
          </CardHeader>
          <CardContent>
            <div className="text-2xl font-bold text-accent">{stat.value}</div>
            <p className="text-xs text-muted-foreground">{stat.subtitle}</p>
          </CardContent>
        </Card>
      ))}
    </div>
  );
};

export default Statistics;
