import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/dialog";
import { UserRoundCog } from "lucide-react";
import SelectController from "../form-fields/SelectController";
import XanoAPI from "../../lib/XanoAPI";
import { useAuth } from "../../lib/AuthContext";

const statusSchema = z.object({
  status: z.enum(["active", "deactivated", "invited"], {
    required_error: "Status is required",
  }),
  role: z.string(),
});

type StatusValues = z.infer<typeof statusSchema>;

interface UserStatusModalProps {
  userId: number;
  userStatus: "active" | "deactivated" | "invited";
  userRole: string;
  onSave: () => void; // Handler to call when save is successful
  agencyId: number | null;
}

export function UserStatusModal({
  userId,
  userStatus,
  userRole,
  onSave,
  agencyId,
}: UserStatusModalProps) {
  const auth = useAuth();
  const [user, setUser] = useState<PlatformUser | null>(null);
  const [roles, setRoles] = useState<{ label: string; value: string }[]>([]);
  const form = useForm<StatusValues>({
    resolver: zodResolver(statusSchema),
    defaultValues: {
      status: userStatus,
      role: userRole,
    },
  });
  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "Deactivated", value: "deactivated" },
    { label: "Invited", value: "invited" },
  ];

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const api = XanoAPI.getInstance();
        const _user = await api.getUserById(userId);
        setUser(_user);

        form.reset({ status: _user.status, role: _user.role });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    const fetchRoles = async () => {
      try {
        const fetchedRoles = auth?.roles;
        const roleOptions = fetchedRoles.map((role: RoleType) => ({
          label: role.role,
          value: role.id.toString(),
        }));

        setRoles(roleOptions);
      } catch (error) {
        console.error("Error fetching roles:", error);
      }
    };

    fetchUser();
    fetchRoles();
  }, [userId, form, agencyId]);

  const onSubmit = async (data: StatusValues) => {
    if (user) {
      try {
        const api = XanoAPI.getInstance();
        const updatedUser = {
          ...user,
          status: data.status,
          permissions_id: parseInt(data.role),
        };
        await api.updateUserStatus(userId, updatedUser);
        // console.log("User status and role updated:", data.status, data.role); /
        form.reset();
        onSave();
      } catch (error) {
        console.error("Error updating user status and role:", error);
      }
    }
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" className="text-accent hover:text-white">
          <UserRoundCog className="w-4 h-4 " />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>User Status & Role</DialogTitle>
          <DialogDescription>
            Make changes to your agent's status and role.
          </DialogDescription>
        </DialogHeader>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="grid gap-0 py-4">
              <SelectController
                name="status"
                label="User Status"
                options={statusOptions}
                defaultValue={userStatus}
              />
              <SelectController
                name="role"
                label="User Role"
                options={roles}
                defaultValue={String(user?.permissions_id)}
              />
            </div>
            <DialogFooter>
              <Button type="submit">Save changes</Button>
              <Button variant="secondary" onClick={() => form.reset()}>
                Cancel
              </Button>
            </DialogFooter>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
}
