import Cookies from "js-cookie";

export default class XanoAPI {
  private static instance: XanoAPI;
  private readonly XANO_ENDPOINT =
    "https://xzkw-tynl-zqtb.n7c.xano.io/api:UIrow74p/";
  private secure = this.isProduction();

  private constructor() {}

  /**
   * Returns the singleton instance of the XanoAPI class.
   * @returns {XanoAPI} The singleton instance.
   */
  public static getInstance(): XanoAPI {
    if (!XanoAPI.instance) {
      XanoAPI.instance = new XanoAPI();
    }
    return XanoAPI.instance;
  }

  /**
   * Method to check if the environment is production
   * @returns {boolean} True if production; otherwise, false.
   */
  public isProduction(env?: string): boolean {
    if (env && env === "production") return true;

    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "dev.app.retireplanningmax.com"
    ) {
      return false;
    }
    return true;
  }

  /**
   * Sets the authentication token in cookies.
   * @param {string} token - The authentication token to set.
   */
  setToken(token: string) {
    Cookies.set("authToken", token, {
      expires: 7,
      secure: this.secure,
      sameSite: "strict",
    });
  }

  /**
   * Retrieves the authentication token from cookies.
   * @returns {string | undefined} The authentication token if it exists.
   */
  getToken(): string | undefined {
    return Cookies.get("authToken");
  }

  /**
   * Removes the authentication token from cookies.
   */
  removeToken() {
    Cookies.remove("authToken");
  }

  /**
   * Sets the user id in cookies.
   * @param {string | number} userId - The user id to set.
   */
  setUserId(userId: string | number): void {
    Cookies.set("userId", userId.toString(), {
      expires: 7,
      secure: this.secure,
      sameSite: "strict",
    });
  }

  /**
   * Retrieves the user id from cookies.
   * @returns {number} The user id if it exists.
   */
  getUserId(): number {
    const userId = Cookies.get("userId");
    return Number(userId);
  }

  /**
   * Removes the user id from cookies.
   */
  removeUserId() {
    Cookies.remove("userId");
  }

  /**
   * Checks if the user is authenticated.
   * @returns {boolean} True if authenticated; otherwise, false.
   */
  isAuthenticated(): boolean {
    return !!this.getToken();
  }

  /**
   * Signs out the current user by removing the authentication token and user ID.
   */
  signOut() {
    this.removeToken();
    this.removeUserId();
    if (window.location.pathname !== "/sign-in") {
      window.location.href = "/sign-in";
      setTimeout(() => window.location.reload(), 2000);
    }
  }

  /**
   * Handles the response from the API.
   * @param {Response} response - The API response.
   * @returns {Response | null} The handled response or null if unauthorized.
   */
  private handleResponse(response: Response): Response | null {
    if (!response.ok && response.status === 401) {
      this.signOut();
      return null;
    }
    return response;
  }

  /**
   * Gets updated headers for API requests.
   * @returns {Headers} The request headers.
   */
  private get headers(): Headers {
    return new Headers({
      "Content-Type": "application/json",
      "X-Data-Source": this.isProduction() ? "live" : "dev",
      Authorization: `Bearer ${this.getToken()}`,
    });
  }

  /**
   * Fetches the current user's data from the API.
   * @returns {Promise<PlatformUser | null>} The current user's data or null if an error occurs.
   */
  public async getCurrentUser(): Promise<PlatformUser | null> {
    return this.apiGet<PlatformUser>("auth/me", (data) => {
      if (data && data.id) this.setUserId(data.id);
      return data;
    });
  }

  /**
   * Retrieves all client records from the API.
   * @returns {Promise<Array<ClientType>>} A list of clients.
   */
  public async getAllClients(): Promise<Array<ClientType>> {
    return this.apiGet<Array<ClientType>>("client");
  }

  /**
   * Fetches a client's data by ID.
   * @param {number | string} clientId - The client's ID.
   * @returns {Promise<ClientType | null>} The client's data or null if an error occurs.
   */
  public async getClient(
    clientId: number | string
  ): Promise<ClientType | null> {
    return this.apiGet<ClientType>(`client/${clientId}`);
  }

  /**
   * Fetches the clients for a specific team member by their ID.
   * @param {number} user_id - The ID of the team member.
   * @returns {Promise<Array<Client>>} A list of clients for the team member.
   */
  public async getClientsByMemberId(
    user_id: number
  ): Promise<Array<ClientType>> {
    try {
      const response = await fetch(`${this.XANO_ENDPOINT}clients/${user_id}`, {
        method: "GET",
        headers: this.headers,
      });

      const handledResponse = this.handleResponse(response);
      if (!handledResponse) throw new Error("Unauthorized request");

      const data = await handledResponse.json();
      return data;
    } catch (error) {
      console.error(`Error fetching clients for member ${user_id}:`, error);
      throw error;
    }
  }

  /**
   * Adds a new client record to the API.
   * @param {ClientType} clientData - The data for the new client.
   * @returns {Promise<ClientType>} The newly added client data.
   */
  public async addClient(clientData: ClientType): Promise<ClientType> {
    return this.apiPost<ClientType>("client", clientData);
  }

  /**
   * Updates a client's data by their ID.
   * @param {string} clientId - The ID of the client to update.
   * @param {Partial<ClientType>} clientData - The client data to be updated.
   * @returns {Promise<ClientType>} The updated client data.
   */
  public async updateClient(
    clientId: number | string,
    clientData: Partial<ClientType>
  ): Promise<ClientType> {
    return this.apiPut<ClientType>(`client/${clientId}`, clientData);
  }

  /**
   * Deletes a client record by their ID.
   * @param {string} clientId - The ID of the client to delete.
   * @returns {Promise<void>} Resolves when the client is deleted.
   */
  public async deleteClient(clientId: string): Promise<void> {
    return this.apiDelete(`client/${clientId}`);
  }

  /**
   * Attempts to log in with the provided credentials.
   * @param {AuthUser} formData - The login credentials.
   * @returns {Promise<any>} The response from the login attempt.
   */
  public async login(formData: AuthUser): Promise<any> {
    const data = await this.apiPost<any>("auth/login", formData);
    if (data && data.authToken) {
      this.setToken(data.authToken);
    }
    return data;
  }

  /**
   * Adds a new feedback record to the API.
   * @param {Object} feedbackData - The data for the new feedback.
   * @returns {Promise<Object>} The newly added feedback data.
   */
  public async addFeedback(feedbackData: {
    feedback_text: string;
  }): Promise<Object> {
    return this.apiPost<Object>("feedback", feedbackData);
  }

  /**
   * Queries all feedback records from the API.
   * @returns {Promise<Array<Object>>} An array of feedback records.
   */
  public async getFeedback(): Promise<Array<Object>> {
    return this.apiGet<Array<Object>>("feedback");
  }

  /**
   * Deletes a feedback record by ID.
   * @param {string} feedbackId - The ID of the feedback to delete.
   * @returns {Promise<void>} Resolves when the feedback record is deleted.
   */
  public async deleteFeedback(feedbackId: string): Promise<void> {
    return this.apiDelete(`feedback/${feedbackId}`);
  }

  public async signUp(invite_code: string, password: string): Promise<any> {
    // console.log(inviteCode, password);
    const signupData = {
      invite_code,
      password,
    };

    return this.apiPut<any>("auth/agent_accept", signupData);
  }

  public async getTeamMemberById(userId: number): Promise<TeamMember | null> {
    try {
      const response = await fetch(`${this.XANO_ENDPOINT}user/${userId}`, {
        method: "GET",
        headers: this.headers,
      });

      const handledResponse = this.handleResponse(response);
      if (!handledResponse) throw new Error("Unauthorized request");

      const data = await response.json();
      return data;
    } catch (error) {
      console.error(`Error fetching user ${userId}:`, error);
      throw error;
    }
  }

  /**
   * Updates the user's data by their ID.
   * @param {string} userId - The user's ID.
   * @param {Partial<PlatformUser>} userData - The user data to be updated.
   * @returns {Promise<PlatformUser>} The updated user data.
   */
  public async updateUser(
    userId: string,
    userData: Partial<PlatformUser>
  ): Promise<PlatformUser> {
    return this.apiPut<PlatformUser>(`user/${userId}`, userData);
  }

  /**
   * Fetches the agency data from the API.
   * @returns {Promise<any>} The agency data.
   */
  public async getAgency(): Promise<any> {
    try {
      const response = await fetch(`${this.XANO_ENDPOINT}agency`, {
        method: "GET",
        headers: this.headers,
      });
      // console.log(response);

      const handledResponse = this.handleResponse(response);
      if (!handledResponse) throw new Error("Unauthorized request");

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching agency data:", error);
      throw error;
    }
  }

  public async createAgency(agencyData: {
    agency_name: string;
    agency_creator: number;
    agency_token: string;
  }): Promise<any> {
    return this.apiPost<any>("agency", agencyData);
  }

  public async sendInviteEmail(email: string, name: string): Promise<string> {
    try {
      const response = await fetch(`${this.XANO_ENDPOINT}auth/agent_invite`, {
        method: "POST",
        headers: this.headers,
        body: JSON.stringify({ name, email }),
      });

      const handledResponse = this.handleResponse(response);
      if (!handledResponse) throw new Error("Unauthorized request");

      // No content expected from a successful invite email request
      return "Succesfully sent invite";
    } catch (error) {
      console.error(`Error sending invite email to ${email}:`, error);
      throw error;
    }
  }

  /**
   * Updates the agency name.
   * @param {string} name - The new name of the agency.
   * @returns {Promise<void>} Resolves when the agency name is updated.
   */
  public async updateAgencyName(
    agencyId: number,
    name: string
    // token: string,
    // creatorId: number
  ): Promise<void> {
    const agencyData = {
      agency_name: name,
      // agency_token: token,
      // agency_creator: creatorId,
    };

    await this.apiPatch(`agency/${agencyId}`, agencyData);
  }

  /**
   * Fetches the user preferences for the current user from the API.
   * @returns {Promise<Object | false>} The user preferences if they exist, or false if not found or an error occurs.
   */
  public async getUserPreferences(): Promise<Object | false> {
    try {
      const userId = this.getUserId();
      if (!userId) throw new Error("User ID not found");

      const response = await fetch(`${this.XANO_ENDPOINT}preferences`, {
        method: "GET",
        headers: this.headers,
      });

      if (!response.ok) {
        console.error("Failed to fetch preferences:", response.statusText);
        return false;
      }

      const handledResponse = this.handleResponse(response);
      if (!handledResponse) throw new Error("Unauthorized request");

      const preferencesArray = await handledResponse.json();
      console.log("Fetched preferences array:", preferencesArray);

      // Check if preferencesArray is an array
      if (!Array.isArray(preferencesArray)) {
        console.error("Unexpected response format:", preferencesArray);
        return false;
      }

      const userPreferences = preferencesArray.find(
        (pref) => pref.user_id === userId
      );
      return userPreferences || false;
    } catch (error) {
      console.error("Error fetching user preferences:", error);
      return false;
    }
  }

  /**
   * Adds new user preferences to the API.
   * @param {Object} preferencesData - The data for the new user preferences.
   * @returns {Promise<Object>} The newly added user preferences data.
   */
  public async addPreferences(preferencesData: Object): Promise<Object> {
    return this.apiPost<Object>("preferences", preferencesData);
  }

  /**
   * Updates user preferences by ID.
   * @param {string} userId - The ID of the user.
   * @param {PlatformUserPreferences} preferencesData - The preference data to be updated.
   * @returns {Promise<Object | null>} The updated preferences data or null if not found.
   */
  public async updatePreferencesByUserId(
    userId: string,
    preferencesData: PlatformUserPreferences
  ): Promise<Object | null> {
    try {
      const preferencesArray = await this.apiGet<Array<any>>("preferences");

      if (!Array.isArray(preferencesArray)) {
        console.error("Unexpected response format:", preferencesArray);
        return null;
      }

      const userPreferences = preferencesArray.find(
        (pref) => parseInt(userId) === pref.user_id
      );

      if (!userPreferences) {
        console.log("No preferences found for the current user");
        return null;
      }

      return this.apiPut<Object>(
        `preferences/${userPreferences.id}`,
        preferencesData
      );
    } catch (error) {
      console.error("Error updating preferences:", error);
      return null;
    }
  }

  /**
   * Resets the user's password.
   * @param {string | number} userId - The ID of the user whose password is to be reset.
   * @param {string} newPassword - The new password for the user.
   * @returns {Promise<void>} A promise that resolves when the password has been reset.
   */
  public async resetPassword(
    userId: string | number,
    newPassword: string
  ): Promise<void> {
    await this.apiPut<void>(`user/${userId}/reset`, { password: newPassword });
    console.log(`Password reset successfully for user: ${userId}`);
  }

  /**
   * Generates a new agent report.
   * @param {number | string} clientId - The client ID for which to generate the report.
   * @returns {Promise<string>} The URL of the generated report.
   */
  public async generateAgentReport(clientId: number | string): Promise<string> {
    const data = await this.apiPost<any>("report_agent", {
      client_id: clientId,
    });
    return data?.response?.result?.data?.file_url;
  }

  public async getUsersByAgencyId(
    agencyId: number
  ): Promise<Array<TeamMember>> {
    try {
      const response = await fetch(`${this.XANO_ENDPOINT}users`, {
        method: "GET",
        headers: this.headers,
      });

      const handledResponse = this.handleResponse(response);
      if (!handledResponse) throw new Error("Unauthorized request");

      const users = await response.json();
      // console.log(users, 888);
      return users;
    } catch (error) {
      console.error(`Error fetching users for agency ${agencyId}:`, error);
      throw error;
    }
  }

  public async addAgent(agentData: PlatformUser): Promise<PlatformUser> {
    return this.apiPost<PlatformUser>("agency", agentData);
  }

  public async getAgents(): Promise<Array<PlatformUser>> {
    return this.apiGet<Array<PlatformUser>>("agency");
  }

  public async removeAgent(roleId: string): Promise<void> {
    return this.apiDelete(`role/${roleId}`);
  }

  /**
   * Fetches permissions of a role by its ID.
   * @param {number} roleId - The ID of the role.
   * @returns {Promise<{ id: number, role: string, permissions: any[] }>} The role data including its permissions.
   */
  public async getRoleById(
    roleId: number
  ): Promise<{ id: number; role: string; permissions: any[] }> {
    try {
      const response = await fetch(`${this.XANO_ENDPOINT}roles/${roleId}`, {
        method: "GET",
        headers: this.headers,
      });

      const handledResponse = this.handleResponse(response);
      if (!handledResponse) throw new Error("Unauthorized request");

      const data = await handledResponse.json();
      return data;
    } catch (error) {
      console.error(`Error fetching role with ID ${roleId}:`, error);
      throw error;
    }
  }

  /**
   * Adds a new role to the currently authenticated user's agency.
   * @param {RoleType} roleData - The data for the new role.
   * @returns {Promise<RoleType>} The newly added role data.
   */
  public async addRole(roleData: RoleType): Promise<RoleType> {
    return this.apiPost<RoleType>("roles", roleData);
  }

  /**
   * Edits a role if the currently authenticated user is permitted to.
   * @param {number} roleId - The ID of the role to update.
   * @param {Partial<RoleType>} roleData - The role data to be updated.
   * @returns {Promise<RoleType>} The updated role data.
   */
  public async updateRole(
    roleId: number,
    roleData: Partial<RoleType>
  ): Promise<RoleType> {
    return this.apiPatch<RoleType>(`roles/${roleId}`, roleData);
  }

  public async getRoles(agency_id: number): Promise<RoleType[]> {
    try {
      const response = await fetch(
        `${this.XANO_ENDPOINT}agency/${agency_id}/roles`,
        {
          method: "GET",
          headers: this.headers,
        }
      );

      const handledResponse = this.handleResponse(response);
      if (!handledResponse) throw new Error("Unauthorized request");

      const data = await handledResponse.json();
      return data;
    } catch (error) {
      console.error("Error fetching roles:", error);
      throw error;
    }
  }
  /**
   * Fetches a user's data by their ID.
   * @param {number} userId - The ID of the user.
   * @returns {Promise<PlatformUser>} The user's data.
   */
  public async getUserById(userId: number): Promise<PlatformUser> {
    try {
      const response = await fetch(`${this.XANO_ENDPOINT}user/${userId}`, {
        method: "GET",
        headers: this.headers,
      });

      const handledResponse = this.handleResponse(response);
      if (!handledResponse) throw new Error("Unauthorized request");

      const data = await handledResponse.json();
      return data;
    } catch (error) {
      console.error(`Error fetching user with ID ${userId}:`, error);
      throw error;
    }
  }

  /**
   * Updates the user's data by their ID.
   * @param {number} userId - The user's ID.
   * @param {PlatformUser} user - The updated user object.
   * @returns {Promise<void>} Resolves when the user's data is updated.
   */
  public async updateUserStatus(
    userId: number,
    user: PlatformUser
  ): Promise<void> {
    try {
      const response = await fetch(`${this.XANO_ENDPOINT}user/${userId}`, {
        method: "PUT",
        headers: this.headers,
        body: JSON.stringify(user),
      });

      const handledResponse = this.handleResponse(response);
      if (!handledResponse) throw new Error("Unauthorized request");

      // No content expected from a successful update
    } catch (error) {
      console.error(`Error updating user ${userId}:`, error);
      throw error;
    }
  }

  /**
   * Generic GET request handler.
   * @param {string} endpoint - The API endpoint.
   * @param {Function} [processData] - Optional function to process the data.
   * @returns {Promise<any>} The response data.
   */
  private async apiGet<T>(
    endpoint: string,
    processData?: (data: T) => T
  ): Promise<T> {
    try {
      const response = await fetch(`${this.XANO_ENDPOINT}${endpoint}`, {
        method: "GET",
        headers: this.headers,
      });

      const handledResponse = this.handleResponse(response);
      if (!handledResponse) throw new Error("Unauthorized request");

      const data = await response.json();
      return processData ? processData(data) : data;
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
      throw error;
    }
  }

  /**
   * Generic POST request handler.
   * @param {string} endpoint - The API endpoint.
   * @param {Object} bodyData - The request body data.
   * @returns {Promise<any>} The response data.
   */
  private async apiPost<T>(endpoint: string, bodyData: Object): Promise<T> {
    try {
      const response = await fetch(`${this.XANO_ENDPOINT}${endpoint}`, {
        method: "POST",
        headers: this.headers,
        body: JSON.stringify(bodyData),
      });

      const handledResponse = this.handleResponse(response);
      if (!handledResponse) {
        console.log("Unauthorized request");
      }

      return await response.json();
    } catch (error) {
      console.error(`Error posting to ${endpoint}:`, error);
      throw error;
    }
  }

  /**
   * Generic PUT request handler.
   * @param {string} endpoint - The API endpoint.
   * @param {Object} bodyData - The request body data.
   * @returns {Promise<any>} The response data.
   */
  private async apiPut<T>(endpoint: string, bodyData: Object): Promise<T> {
    try {
      const response = await fetch(`${this.XANO_ENDPOINT}${endpoint}`, {
        method: "PUT",
        headers: this.headers,
        body: JSON.stringify(bodyData),
      });

      const handledResponse = this.handleResponse(response);
      if (!handledResponse) throw new Error("Unauthorized request");

      return await response.json();
    } catch (error) {
      console.error(`Error putting to ${endpoint}:`, error);
      throw error;
    }
  }

  /**
   * Generic PATCH request handler.
   * @param {string} endpoint - The API endpoint.
   * @param {Object} bodyData - The request body data.
   * @returns {Promise<any>} The response data.
   */
  private async apiPatch<T>(endpoint: string, bodyData: Object): Promise<T> {
    try {
      const response = await fetch(`${this.XANO_ENDPOINT}${endpoint}`, {
        method: "PATCH",
        headers: this.headers,
        body: JSON.stringify(bodyData),
      });

      const handledResponse = this.handleResponse(response);
      if (!handledResponse) throw new Error("Unauthorized request");

      return await response.json();
    } catch (error) {
      console.error(`Error patching to ${endpoint}:`, error);
      throw error;
    }
  }

  /**
   * Generic DELETE request handler.
   * @param {string} endpoint - The API endpoint.
   * @returns {Promise<void>} Resolves when the request is complete.
   */
  private async apiDelete(endpoint: string): Promise<void> {
    try {
      const response = await fetch(`${this.XANO_ENDPOINT}${endpoint}`, {
        method: "DELETE",
        headers: this.headers,
      });

      const handledResponse = this.handleResponse(response);
      if (!handledResponse) throw new Error("Unauthorized request");

      // No content expected from a DELETE request
    } catch (error) {
      console.error(`Error deleting ${endpoint}:`, error);
      throw error;
    }
  }
}
