import React from "react";
import Header from "../components/header/header";
import Statistics from "../components/statistics/statistics";
// import ClientsTable from "../components/clients/clients-table";
import VideoSection from "../components/dashboard/VideoSection";
import RecentNews from "../components/dashboard/RecentNews";
import AboutCompany from "../components/dashboard/AboutCompany";
import Footer from "../components/footer/footer";

const Dashboard: React.FC = () => {
  return (
    <>
      <div className="hidden flex-col md:flex">
        <Header />
        <div className="flex-1 space-y-4 p-8 pt-6 container mt-10">
          {/* <div className="pt-10 mb-4">
            <h2 className="text-3xl font-bold tracking-tight">Dashboard</h2>
            <p className="text-muted-foreground text-sm">
              Here&apos;s a list of your clients
            </p>
          </div> */}

          <div className="flex mb-4 py-4">
            <VideoSection />
            <RecentNews />
          </div>

          <div className="flex mb-4 py-4">
            <AboutCompany />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Dashboard;
