import { Separator } from "../components/ui/separator";
import { SidebarNav } from "../components/account/components/sidebar-nav";
import Header from "../components/header/header";
import { ProfileForm } from "../components/account/components/profile-form";
import { ChangePassword } from "../components/account/components/change-password";
import { UserPreferencesForm } from "../components/account/components/user-preferences";
import MyTeam from "../components/account/components/my-team";
import Reports from "../components/account/components/reports";
import { isProduction } from "../lib/utils";
import { useAuth } from "../lib/AuthContext";

export default function Account() {
  const auth = useAuth();
  const permissions = auth.currentUser?.user_permissions[0];

  const sidebarNavItems = [
    {
      title: "Profile",
      href: "/account/",
    },
    {
      title: "Change Password",
      href: "/account/password/",
    },
  ];

  if (permissions.can_edit_preferences) {
    sidebarNavItems.push({
      title: "User Preferences",
      href: "/account/preferences/",
    });
  }

  if (!isProduction() && permissions.can_manage_users) {
    sidebarNavItems.push({
      title: "My Team",
      href: "/account/my-team/",
    });
  }

  if (!isProduction() && permissions.can_view_reports) {
    sidebarNavItems.push({
      title: "View Reports",
      href: "/account/reports/",
    });
  }

  return (
    <>
      <Header />
      <div className="hidden space-y-6 p-10 pb-16 md:block">
        <div className="space-y-0.5 mt-12">
          <h2 className="text-2xl font-bold tracking-tight">My Account</h2>
          <p className="text-muted-foreground">
            Manage your account settings and set e-mail preferences.
          </p>
        </div>
        <Separator className="my-6" />
        <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
          <aside className="-mx-4 lg:w-1/5">
            <SidebarNav items={sidebarNavItems} />
          </aside>
          <div className="flex-1 lg:max-w-3xl">
            {window.location.pathname === "/account/" && <ProfileForm />}
            {window.location.pathname === "/account/password/" && (
              <ChangePassword />
            )}
            {window.location.pathname === "/account/preferences/" && (
              <UserPreferencesForm />
            )}
            {window.location.pathname === "/account/my-team/" && <MyTeam />}
            {window.location.pathname === "/account/reports/" && <Reports />}
          </div>
        </div>
      </div>
    </>
  );
}
