import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { Icons } from "../../utils/icons";
import Sidebar from "../sidebar/clients-sidebar";
import PersonalInformation from "./details/personal-information";
import FinancialInformation from "./details/financial-information";
import Comparisons from "./details/comparisons";
import Results from "./details/results";
import XanoAPI from "../../lib/XanoAPI";

const COOKIE_NAME = "activeTab";

export default function EditForms() {
  const api = XanoAPI.getInstance();
  const [client, setClient] = useState<ClientType | null>(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);
  // const [activeTab, setActiveTab] = useState("personal-information");
  const [activeTab, setActiveTab] = useState<string>(
    Cookies.get(COOKIE_NAME) || "personal-information"
  );
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [pendingTab, setPendingTab] = useState<string | null>(null);
  const [formsDirty, setFormsDirty] = useState({
    personalInformation: [],
    financialInformation: [],
  });
  const { id } = useParams();

  useEffect(() => {
    (async () => {
      try {
        await fetchClientData(id || 0);
      } catch (error) {
        console.error("Failed to fetch client data", error);
      }
    })();
  }, [id, updateTrigger]);

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (
        formsDirty.personalInformation.length ||
        formsDirty.financialInformation.length
      ) {
        const message =
          "You have unsaved changes! Are you sure you want to leave?";
        e.preventDefault();
        e.returnValue = message;
        return message;
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [formsDirty]);

  const fetchClientData = async (id: string | number) => {
    if (!id) {
      console.error("No client ID provided");
      return;
    }

    try {
      const data = await api.getClient(id);
      setClient(data);
    } catch (error) {
      console.error("Failed to fetch client data:", error);
    }
  };

  const handleUpdate = () => setUpdateTrigger((prev) => prev + 1);

  const handleTabChange = (newTab: string) => {
    // console.log(newTab, 888);
    if (formsDirty.personalInformation.length) {
      console.log("you have unsaved changes in personal information");
      // setDisableFinancialInformation(true);
      setIsDialogOpen(true);
    }

    if (formsDirty.financialInformation.length) {
      console.log("you have unsaved changes in financial information");
      // setDisablePersonalInformation(true);
      setIsDialogOpen(true);
    }
    Cookies.set(COOKIE_NAME, newTab);
  };

  const confirmNavigation = () => {
    setActiveTab(pendingTab || "personal-information");
    resetFormsDirtyState();
    closeDialog();
  };

  const cancelNavigation = () => closeDialog();

  const closeDialog = () => {
    setIsDialogOpen(false);
    setPendingTab(null);
  };

  const resetFormsDirtyState = () =>
    setFormsDirty({
      personalInformation: [],
      financialInformation: [],
    });

  return (
    <div className="flex-1 space-y-4 p-8 pt-6 container">
      <div className="pt-10 mt-10">
        <h2 className="text-3xl font-bold tracking-tight text-accent flex items-center gap-2">
          <Icons.userFramed className="w-12 h-12" />
          Edit Client
        </h2>
        <p className="text-muted-foreground">
          This is where you can edit a client&apos;s details
        </p>
        <Sidebar activeTab={activeTab} />
      </div>

      <div className="pt-10">
        <ul className="flex font-sans bg-gray-100 rounded">
          <li
            className={`text-gray w-full text-left text-base py-3 px-4 cursor-pointer flex rounded ${
              activeTab === "personal-information" ? "bg-accent text-white" : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              setActiveTab("personal-information");
              Cookies.set(COOKIE_NAME, "personal-information");
            }}
          >
            <Icons.personal className="w-6 h-6 mr-2" />
            Personal Information
          </li>
          <li
            className={`text-gray w-full text-left text-base py-3 px-4 cursor-pointer flex rounded ${
              activeTab === "financial-information"
                ? "bg-accent text-white"
                : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              setActiveTab("financial-information");
              Cookies.set(COOKIE_NAME, "financial-information");
            }}
          >
            <Icons.financial className="w-6 h-6 mr-2" />
            Financial Information
          </li>
          <li
            className={`text-gray w-full text-left text-base py-3 px-4 cursor-pointer flex rounded ${
              activeTab === "comparisons" ? "bg-accent text-white" : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              setActiveTab("comparisons");
              Cookies.set(COOKIE_NAME, "comparisons");
            }}
          >
            <Icons.newspaper className="w-6 h-6 mr-2" />
            Comparisons
          </li>
          <li
            className={`text-gray w-full text-left text-base py-3 px-4 cursor-pointer flex rounded ${
              activeTab === "results" ? "bg-accent text-white" : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              setActiveTab("results");
              Cookies.set(COOKIE_NAME, "results");
            }}
          >
            <Icons.results className="w-6 h-6 mr-2" />
            Results
          </li>
        </ul>
        <div
          className={
            activeTab === "personal-information" ? "visible" : "hidden"
          }
        >
          {client && (
            <PersonalInformation
              client={client}
              onUpdate={handleUpdate}
              formsDirty={formsDirty}
              setFormsDirty={setFormsDirty}
              fetchClientData={fetchClientData}
              resetFormsDirtyState={resetFormsDirtyState}
            />
          )}
        </div>

        <div
          className={
            activeTab === "financial-information" ? "visible" : "hidden"
          }
        >
          {client && (
            <FinancialInformation
              client={client}
              onUpdate={handleUpdate}
              handleTabChange={handleTabChange}
              formsDirty={formsDirty}
              setFormsDirty={setFormsDirty}
              fetchClientData={fetchClientData}
              resetFormsDirtyState={resetFormsDirtyState}
            />
          )}
        </div>
        <div className={activeTab === "comparisons" ? "visible" : "hidden"}>
          {client && <Comparisons client={client} />}
        </div>
        <div className={activeTab === "results" ? "visible" : "hidden"}>
          {client && <Results client={client} />}
        </div>
      </div>
    </div>
  );
}
